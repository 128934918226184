
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import TraineeEditModal from '@/layout/header/partials/trainee/TraineeEditDrawer.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import moment from 'moment';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainee show',
  components: {
    Form,
    Field,
    Datatable,
    TraineeEditModal,
    Swal,
    ElNotification,
  },
  data() {
    return {
      moment: '' as any,
      api_url: '',
      traineeList: [],
      industry: [],
      trainee: {
        entity_id: '' as any,
        training_institute_id: '',
        course_info_id: '',
        tranche_id: '' as any,
        batch_info_id: '',
      },
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Father Name',
          key: 'f_name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Mother Name',
          key: 'm_name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Reg No',
          key: 'reg_no',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Ref No',
          key: 'ref_no',
          sortable: true,
          width: '170px',
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          width: '100px',
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Date of Birth',
          key: 'birth_date',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Bank Account No',
          key: 'account_no',
          sortable: true,
          width: '150px',
        },
      ],
      institutes: [],
      associations: [] as any,
      tranches: [],
      batches: [],
      courses: [],
      entity_type: '',
      totalTrainee: 0,
      totalFemale: 0,
      user_role: '',
      loading: false,
      load: false,
      courseInfoData: false,
      showtraineeInfo: false,
      actionEdit: false,
      actionDelete: false,
      componentKey: 0,
      pdfHeader: [] as any,
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
    };
  },
  async created() {
    this.moment = moment;
    await this.associationList();
    await this.getTranches();
    await this.actioncheck();
    this.api_url = this.VUE_APP_API_URL;
    this.emitter.on('trainee-updated', async () => {
      this.getTraineeList();
    });
    this.entity_type = VueCookieNext.getCookie('_seip_entity_type');
    this.userInfo = VueCookieNext.getCookie('_seip_user');
    this.user_role =
      this.userInfo?.employee?.entity_organogram[0]?.entitytyperole?.role_title;
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].heading == 'Trainee') {
            let actions = menu[i].action;
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Delete') {
                this.actionDelete = true;
              }
            }
          }
        }
      }
    },

    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);

      await ApiService.post('trainee/trainee_list_pdf', formData)
        .then((response) => {
          ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async printPdf() {
      let formData = new FormData();

      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);

      let data = `${this.VUE_APP_API_URL}/api/trainee/trainee_list_print`;
      axios.post(data, formData).then((response) => {
        //window.open(data, '_blank');
        document.write(response.data);
        window.print();
        location.reload();
        //open the new window and write your HTML to it
      });
    },

    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('entity_id', this.trainee.entity_id);
      formData.set('tranche_id', this.trainee.tranche_id);
      formData.set('training_institute_id', this.trainee.training_institute_id);
      formData.set('course_info_id', this.trainee.course_info_id);
      formData.set('batch_info_id', this.trainee.batch_info_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/traineeListExport`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          ElNotification.closeAll();
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Trainee List.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async trancheChange() {
      this.trainee.course_info_id = '';
      this.trainee.entity_id = '';
      this.trainee.training_institute_id = '';
      this.trainee.batch_info_id = '';
      this.showtraineeInfo = false;
    },
    async getTranches() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async trainingInstitute() {
      this.trainee.course_info_id = '';
      this.trainee.training_institute_id = '';
      this.trainee.batch_info_id = '';
      this.showtraineeInfo = false;
      this.load = true;
      let entityIds = this.trainee.entity_id;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          entityIds +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseList() {
      this.trainee.course_info_id = '';
      this.trainee.batch_info_id = '';
      this.showtraineeInfo = false;
      this.load = true;
      let entity_id = this.trainee.entity_id;
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'course/list?entity_id=' +
          entity_id +
          '&tranche=' +
          this.trainee.tranche_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBatchList() {
      this.trainee.batch_info_id = '';
      this.showtraineeInfo = false;
      this.load = true;
      let entity_id = this.trainee.entity_id;
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        'batch/list?entity_id=' +
          entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTraineeList() {
      if (
        this.trainee.course_info_id != '' &&
        this.trainee.tranche_id != '' &&
        this.trainee.batch_info_id != ''
      ) {
        this.load = true;
        await ApiService.get(
          'trainee/list?entity_id=' +
            this.trainee.entity_id +
            '&tranche_id=' +
            this.trainee.tranche_id +
            '&training_institute_id=' +
            this.trainee.training_institute_id +
            '&course_info_id=' +
            this.trainee.course_info_id +
            '&batch_info_id=' +
            this.trainee.batch_info_id
        )
          .then((response) => {
            this.load = false;
            this.traineeList = response.data.data;
            this.totalTrainee = this.traineeList.length;
            this.totalFemale = 0;
            for (var i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i]['gender'] == 'Female') {
                this.totalFemale++;
              }
            }
            this.showtraineeInfo = true;
            this.componentKey += 1;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please Select All Required Field.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    Delete(id) {
      Swal.fire({
        title: 'Warning!',
        html: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('trainee/delete/' + `${id}`)
            .then((response) => {
              if ((response.data.status = 'error')) {
                Swal.fire({
                  title: 'Error!',
                  html: response.data.message,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
              } else {
                Swal.fire({
                  title: 'Deleted!',
                  text: response.data.data,
                  icon: 'success',
                  buttonsStyling: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                }).then(() => {
                  this.emitter.emit('trainee-updated', true);
                });
              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    editTrainee(data) {
      this.emitter.emit('trainee-edit', data);
    },
  },
});
